<template>
  <div>
    <header class="header bg-white br-bottom">
      <div class="header-logo h2">Notes</div>
      <div class="header-rg"></div>
    </header>
    <div class="row row-32">
      <div class="col-lg-3">
        <div class="block-layout height-js no-min">
          <div class="notes-list_scroll">
            <div class="lb-mb-8">
              <div class="row row-8 justify-content-start align-items-center">
                <div class="col-auto">
                  <div class="color-grey text-md">Personal notes</div>
                </div>
                <div class="col-auto">
                  <button
                    class="btn btn-icon btn-ghost"
                    @click="newNotes"
                  >
                    <img src="@/assets/img/icons/icon-plus.svg" alt="icon">
                  </button>
                </div>
              </div>
            </div>
            <div class="lb-mb-32">
              <div v-if="!loading_notes">
                <template v-if="notes_persona">
                  <ul v-if="notes_persona.length > 0" class="menu-list-ms type-two mr-r-l">
                    <li
                      v-for="(item, i) in notes_persona"
                      :key="i + 'b'"
                      :class="{'is-active': notes_active.id === item.id}"
                      @click="notes_active = item"
                    >
                      <span class="icon"><svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M21 11H3v2h18v-2zM3 16h12v2H3v-2zM21 6H3v2h18V6z" class="svg-fill"/></svg></span>
                      <span>{{item.title}}</span>
                    </li>
                  </ul>
                  <div class="lb-tp-15" v-else>None</div>
                  <ul class="menu-list-ms type-two mr-r-l">
                    <li
                      class="is-active menu-list-ms_input"
                      ref="new_notes"
                      v-if="is_new"
                    >
                      <div
                        class="menu-list-ms_loading"
                        @click="saveNewNotes($event)"
                        v-if="loading_new"
                      >
                        <div class="loader-circle">
                          <img src="@/assets/img/loader-icon.png" alt="loader">
                        </div>
                      </div>
                      <div class="menu-list-ms_outline" @click="saveNewNotes($event)"></div>
                      <span class="icon"><svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M21 11H3v2h18v-2zM3 16h12v2H3v-2zM21 6H3v2h18V6z" class="svg-fill"/></svg></span>
                      <span>
                    <input
                      placeholder="Name"
                      type="text"
                      class="input-in"
                      @keypress.enter="saveNewNotes($event)"
                      v-model="name_new"
                    >
                  </span>
                    </li>
                  </ul>
                </template>
              </div>
              <div v-else class="loader-wrapper">
                <div class="loader-circle lx">
                  <img src="@/assets/img/loader-icon.png" alt="loader">
                </div>
              </div>
            </div>
            <div class="block-middle mr-4 lb-mb-15">
              <span class="color-grey text-md">Clients notes</span>
              <span class="icon">
              <img src="@/assets/img/icons/icon-help_outline.svg" alt="icon">
            </span>
            </div>
            <div v-if="!loading_notes">
              <template v-if="notes_clients">
                <ul v-if="notes_clients.length > 0" class="users-list-two mr-r-l">
                  <li
                    v-for="(item, i) in notes_clients"
                    :key="i + 's'"
                    :class="{'is-active': notes_active.id === item.id}"
                    @click="notes_active = item; notes_active.cl = true"
                  >
              <span class="image">
                <img v-if="item.photo && checkURL(item.photo)" :src="item.photo" alt="imag">
                <img v-else src="@/assets/img/default-user-avatar.png" alt="imag">
              </span>
                    <span>Note for {{item.fullname}}</span>
                  </li>
                </ul>
                <div v-else>None</div>
              </template>
            </div>
            <div v-else class="loader-wrapper">
              <div class="loader-circle lx">
                <img src="@/assets/img/loader-icon.png" alt="loader">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-9">
        <div class="block-layout height-js no-min">
          <div class="notes_scroll">
            <template v-if="!loading_editor">
              <template v-if="notes_active">
                <editor-notes
                  :shared="true"
                  :private-note="true"
                  :notes="notes_active"
                ></editor-notes>
              </template>
            </template>
            <div v-else class="loader-wrapper">
              <div class="loader-circle">
                <img src="@/assets/img/loader-icon.png" alt="loader">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EditorNotes from '@/components/EditorNotesMy.vue'
export default {
  data () {
    return {
      name_new: '',
      loading_new: false,
      loading_notes: false,
      loading_editor: true,
      notes_active: '',
      is_new: false,
      notes_model: {
        id: '',
        title: '',
        body: `
          `
      }
    }
  },
  computed: {
    notes_cm: function () {
      return this.$store.getters.notes_cm
    },
    notes_persona: function () {
      return this.$store.getters.notes_cm.personal
    },
    notes_clients: function () {
      return this.$store.getters.notes_cm.clients
    }
  },
  methods: {
    newNotes: function () {
      const c = JSON.parse(JSON.stringify(this.notes_model))
      this.notes_active = c
      this.is_new = true
      setTimeout(() => {
        this.$refs.new_notes.querySelector('.input-in').focus()
      }, 50)
    },
    saveNewNotes: function (e) {
      const c = JSON.parse(JSON.stringify(this.notes_model))
      if (this.name_new.length > 0) {
        this.$refs.new_notes.classList.remove('error')
        this.loading_new = true
        c.title = this.name_new
        c.id = Math.random().toString(36).substr(2, 9)
        this.$store.dispatch('addNotesPersonal', c)
          .finally(() => {
            this.notes_active = this.notes_persona[this.notes_persona.length - 1]
            this.loading_new = false
            this.is_new = false
            this.name_new = ''
          })
      } else {
        this.$refs.new_notes.classList.add('error')
      }
    },
    checkURL: function (url) {
      return (url.match(/\.(jpeg|jpg|gif|png)$/) != null)
    },
    fetchNotesClients: function () {
      if (this.notes_cm === '') {
        this.loading_notes = true
      }
      this.$store.dispatch('fetchNotesClients')
        .finally(() => {
          this.loading_notes = false
          this.loading_editor = false
          this.$utils.scrollHG()
        })
    }
  },
  mounted () {
    this.$utils.scrollHG()
    window.addEventListener('resize', this.$utils.scrollHG)
    this.fetchNotesClients()
  },
  watch: {
    loading_editor: function (v) {
      if (!v && this.notes_persona.length > 0) {
        this.notes_active = this.notes_persona[0]
      }
    }
  },
  components: {
    EditorNotes
  }
}
</script>

<style lang="scss">
.notes_scroll{
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 8px;
  margin: 0 -8px;
}
.notes-list_scroll{
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 14px;
  margin: 0 -14px;
}
</style>
