<template>
  <div>
    <div v-if="!loadingNotes">
      <div class="lb-mb-20">
        <div class="row justify-content-between align-items-center">
          <div class="col-auto">
            <div class="h3 lb-mb-4">Notes</div>
            <div v-if="privateNote">
              <div class="block-middle mr-4 lb-mb-15">
                <span class="icon"><img src="@/assets/img/icons/icon-lock_outline.svg" alt="icon"></span>
                <span class="color-grey">Clients notes</span>
              </div>
            </div>
            <div v-else class="color-grey">Introduced notes on John Doe</div>
          </div>
          <div class="col-auto">
            <div class="btn-wrapper right">
              <button class="btn btn-ghost btn-icon tooltips-ms" @click="editor.chain().focus().undo().run()">
                <img src="@/assets/img/icons/icon-undo.svg" alt="icon">
                <div class="tooltips">
                  <span>Undo</span>
                  <span>Ctrl + Z</span>
                </div>
              </button>
              <button class="btn btn-ghost btn-icon tooltips-ms" @click="editor.chain().focus().redo().run()">
                <img src="@/assets/img/icons/icon-redo.svg" alt="icon">
                <div class="tooltips">
                  <span>Undo</span>
                  <span>Ctrl + Y</span>
                </div>
              </button>
              <button
                class="btn btn-ghost btn-icon btn-loader"
                @click="saveNotesClients()"
                :class="{'loader': loadingLocal}"
                v-if="notes.cl"
              >
                <img src="@/assets/img/icons/icon-upload.svg" alt="icon">
              </button>
              <button
                class="btn btn-ghost btn-icon btn-loader"
                @click="saveNotes()"
                :class="{'loader': loadingLocal}"
                v-else
              >
                <img src="@/assets/img/icons/icon-upload.svg" alt="icon">
              </button>
              <button v-if="shared" class="btn btn-ghost btn-icon">
                <img src="@/assets/img/icons/icon-share.svg" alt="icon">
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="editor-wrapper" v-if="editor" ref="editor" @contextmenu="openEditorMenu($event)" @keyup="handleKeyEvent">
        <editor-content :editor="editor" />
        <div>
          <span @click="closeEditorMenu()" class="editor-overlay"></span>
          <div class="editor-menu">
            <ul class="menu">
              <li
                @click="editor.chain().focus().toggleHeading({ level: 1 }).run(), closeEditorMenu()"
                :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }"
              >
                <span class="icon"><img src="@/assets/img/icons/icon-h1.svg" alt="icon"></span><span>Heading 1</span>
              </li>
              <li
                @click="editor.chain().focus().toggleHeading({ level: 2 }).run(), closeEditorMenu()"
                :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }"
              >
                <span class="icon"><img src="@/assets/img/icons/icon-h2.svg" alt="icon"></span><span>Heading 2</span>
              </li>
              <li
                @click="editor.chain().focus().toggleHeading({ level: 3 }).run(), closeEditorMenu()"
                :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }"
              >
                <span class="icon"><img src="@/assets/img/icons/icon-h3.svg" alt="icon"></span><span>Heading 3</span>
              </li>
              <li
                class="menu-hr"
                @click="editor.chain().focus().setParagraph().run(), closeEditorMenu()"
                :class="{ 'is-active': editor.isActive('paragraph') }"
              >
                <span class="icon"><img src="@/assets/img/icons/icon-paragraph.svg" alt="icon"></span><span>Paragraph text</span>
              </li>
              <li
                @click="editor.chain().focus().toggleTaskList().run(), closeEditorMenu()"
                :class="{ 'is-active': editor.isActive('taskList') }"
              >
                <span class="icon"><img src="@/assets/img/icons/icon-check-box.svg" alt="icon"></span><span>Checklist</span>
              </li>
              <li
                @click="editor.chain().focus().toggleBulletList().run(), closeEditorMenu()"
                :class="{ 'is-active': editor.isActive('bulletList') }"
              >
                <span class="icon"><img src="@/assets/img/icons/icon-list_bulleted.svg" alt="icon"></span><span>Bulleted list</span>
              </li>
              <li
                @click="editor.chain().focus().toggleOrderedList().run(), closeEditorMenu()"
                :class="{ 'is-active': editor.isActive('orderedList') }"
              >
                <span class="icon"><img src="@/assets/img/icons/icon-list_numbered.svg" alt="icon"></span><span>Numbered list</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="loader-wrapper">
      <div class="loader-circle">
        <img src="@/assets/img/loader-icon.png" alt="loader">
      </div>
    </div>
  </div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2'
import { defaultExtensions } from '@tiptap/starter-kit'
import Placeholder from '@tiptap/extension-placeholder'
import TaskList from '@tiptap/extension-task-list'
import TaskItem from '@tiptap/extension-task-item'
export default {
  props: {
    shared: {
      type: Boolean,
      default: false
    },
    privateNote: {
      type: Boolean,
      default: false
    },
    notes: {
      type: Object
    }
  },
  data () {
    return {
      editor: null,
      loadingNotes: true,
      loadingLocal: false
    }
  },
  computed: {
    editorWait () {
      return this.editor
    }
  },
  methods: {
    openEditorMenu: function (e) {
      e.preventDefault()
      const $editor = this.$refs.editor
      const $menu = $editor.querySelector('.editor-menu')
      $editor.querySelector('.ProseMirror').setAttribute('contenteditable', false)
      $editor.classList.add('is-active')
      $menu.style.left = e.pageX + 'px'
      $menu.style.top = e.pageY + 'px'
    },
    closeEditorMenu: function () {
      const $editor = this.$refs.editor
      $editor.querySelector('.ProseMirror').setAttribute('contenteditable', true)
      $editor.classList.remove('is-active')
    },
    handleKeyEvent (e) {
      e.preventDefault()
      const $editor = this.$refs.editor
      const $menu = $editor.querySelector('.editor-menu')
      if ($editor.classList.contains('is-active')) {
        if (e.code === 'ArrowDown') {
          if (!$menu.querySelector('li.active')) {
            const f = $menu.querySelectorAll('li')[0]
            f.classList.add('active')
          } else {
            const a = $menu.querySelector('li.active')
            if (a.nextElementSibling) {
              a.nextElementSibling.classList.add('active')
              a.classList.remove('active')
            }
          }
        }
        if (e.code === 'ArrowUp') {
          if (!$menu.querySelector('li.active')) {
            const a = $menu.querySelectorAll('li')[$menu.querySelectorAll('li').length - 1]
            a.classList.add('active')
          } else {
            const a = $menu.querySelector('li.active')
            if (a.previousElementSibling) {
              a.previousElementSibling.classList.add('active')
              a.classList.remove('active')
            }
          }
        }
        if (e.code === 'Space') {
          const a = $menu.querySelector('li.active')
          if (a) {
            a.click()
            a.classList.remove('active')
          }
        }
      }
      if (e.code === 'Slash') {
        $editor.querySelector('.ProseMirror').setAttribute('contenteditable', false)
        if ($editor.classList.contains('is-active')) {
          $editor.querySelector('.ProseMirror').setAttribute('contenteditable', true)
          $editor.classList.remove('is-active')
        } else {
          const { x, y } = this.getCaretCoordinates()
          this.editor.chain().focus().undo().run()
          $editor.classList.add('is-active')
          $menu.style.left = x + 'px'
          $menu.style.top = y + 'px'
        }
      }
    },
    getCaretCoordinates () {
      let x = 0
      let y = 0
      const isSupported = typeof window.getSelection !== 'undefined'
      if (isSupported) {
        const selection = window.getSelection()
        if (selection.rangeCount !== 0) {
          if (selection.anchorNode.nodeName !== '#text') {
            y = selection.anchorNode.getBoundingClientRect().top + 10
            x = selection.anchorNode.getBoundingClientRect().left + 10
          } else {
            const range = selection.getRangeAt(0).cloneRange()
            range.collapse(true)
            const rect = range.getClientRects()[0]
            if (rect) {
              x = rect.left + 4
              y = rect.top + 4
            }
          }
        }
      }
      return { x, y }
    },
    saveNotes: function () {
      this.notes.body = this.editor.getHTML()
      this.loadingLocal = true
      this.$store.dispatch('saveNotesPersonal', this.notes)
        .finally(() => {
          this.loadingLocal = false
        })
    },
    saveNotesClients: function () {
      this.notes.body = this.editor.getHTML()
      this.loadingLocal = true
      this.$store.dispatch('saveNotesClients', this.notes)
        .finally(() => {
          this.loadingLocal = false
        })
    }
  },
  mounted () {
    this.loadingNotes = false
    this.editor = new Editor({
      extensions: [
        ...defaultExtensions(),
        Placeholder,
        TaskList,
        TaskItem
      ],
      content: this.notes.body
    })
  },
  beforeDestroy () {
    this.editor.destroy()
  },
  watch: {
    notes: {
      handler (v) {
        this.editor.commands.setContent(v.body)
      },
      deep: true
    }
  },
  components: {
    EditorContent
  }
}
</script>

<style lang="scss">
.editor{
  &-overlay{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 28;
    display: none;
  }
  &-menu{
    background-color: #FFFFFF;
    border-radius: 16px;
    padding: 16px;
    filter: drop-shadow(0px 8px 24px rgba(0, 0, 0, 0.12));
    display: inline-block;
    min-width: 220px;
    position: fixed;
    display: none;
    li{
      padding: 4px;
      border-radius: 8px;
      margin-bottom: 4px;
      color: #161616;
      font-size: 14px;
      display: flex;
      align-items: center;
      cursor: pointer;
      transition: all .34s ease-out;
      &.is-active{
        background-color: rgba(0, 0, 0, 0.04);
      }
      &.active{
        background-color: rgba(0, 0, 0, 0.04);
      }
      &.menu-hr{
        margin-bottom: 8px;
        position: relative;
        &:after{
          content: '';
          display: block;
          width: 100%;
          height: 1px;
          position: absolute;
          left: 0;
          top: calc(100% + 4px);
          background-color: rgba(0, 0, 0, 0.08);
        }
      }
    }
    .icon{
      display: inline-block;
      width: 24px;
      line-height: 0;
      margin-right: 8px;
      img{
        width: 100%;
      }
    }
  }
  &-wrapper{
    display: block;
    &.is-active{
      .editor-menu{
        display: block;
        z-index: 6;
      }
      .editor-overlay{
        display: block;
        z-index: 4;
      }
    }
  }
}
@media (min-width: 1200px){
  .editor-menu li:hover{
    background-color: rgba(0, 0, 0, 0.04);
  }
}
.ProseMirror > p{
  user-select: none;
}
.ProseMirror {
  min-height: 400px;
  outline: none;
  color: #161616;
  border-radius: 6px;
  > * + * {
    margin-top: 15px;
  }
  ul{
    list-style: disc;
  }
  ol{
    list-style: decimal;
  }
  ul,
  ol {
    padding: 0 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.2;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0D0D0D;
    color: #FFF;
    font-family: 'JetBrainsMono', monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;
    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0D0D0D, 0.1);
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#0D0D0D, 0.1);
    margin: 2rem 0;
  }
  ul[data-type="taskList"] {
    list-style: none;
    padding: 0;
    li {
      display: flex;
      align-items: center;

      > label {
        flex: 0 0 auto;
        margin-right: 0.5rem;
        line-height: 0;
        margin-bottom: -1px;
      }
    }
    input[type="checkbox"] {
      cursor: pointer;
      width: 14px;
      height: 14px;
    }
  }
}
.ProseMirror p.is-editor-empty:first-child::before {
  content: 'Type “/” to format or add block';
  float: left;
  color: #6F6F6F;
  pointer-events: none;
  height: 0;
}
</style>
